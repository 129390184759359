@import "src/themes/mojito/styles/index.scss";
.my-billing-dashboard {
  .Layout__wrapper {
    width: 100%;
    height: 100%;
  }

  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include lg {
      height: 98%;
    }
    .Dashboard {
      width: 100%;
    }
  }
  &__empty-container {
    height: 100%;
    width: 100%;
    height: calc(100% - 63px);
    @include sm {
      height: 83vh !important;
    }
  }
  &__empty-box {
    height: 78vh;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    background-color: getvar($colors, "neutral", "white") !important;
    @include lg {
      height: 85vh;
    }
    &--inner {
      display: -webkit-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: space(2);
      width: 288px;
      height: 85%;
      padding-top: 24px;
      @include lg {
        width: 385px;
        padding-top: 0;
      }
    }
  }
}
